export const environment = {
  production: false,
  apiBase: 'https://londonhydro-duom-qa.web.app/api/v1',
  firebaseConfig: {    
    apiKey: "AIzaSyD0AJEh9T6Qb_t78OJbxaC3a7jYfIhh6yM",
  	authDomain: "londonhydro-duom-qa.firebaseapp.com",
  	projectId: "londonhydro-duom-qa",
  	storageBucket: "londonhydro-duom-qa.appspot.com",
  	messagingSenderId: "909499958609",
  	appId: "1:909499958609:web:1c5095d5348e9d819bbf70",
  	measurementId: 'G-DMFD2F16BE'
  }
};