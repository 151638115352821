/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GeocodingResponse, Coordinates } from 'src/app/damage-assessment/shared/geocoding.types.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class DamageAssessmentService {
  baseUrl = `https://ineqa.londonhydro.com`;
  userDetailsData: BehaviorSubject<any> = new BehaviorSubject([]);
  private token = '';
  private headerObj = { Authorization: `Bearer ${this.token}` };
  private geoCodeURl = `https://maps.googleapis.com/maps/api/geocode/json`;
  private key = 'AIzaSyD6meuaH6mj771QFTCJbcuWDVrDTdUUyZU';
  constructor(private http: HttpClient, private angularFirestore: AngularFirestore) { }

  getToken(): string {
    return this.token;
  }

  setToken(token: string): void {
    this.token = token;
  }

  refreshToken(): Observable<string> {
    const tokenUrl = `${this.baseUrl}/INEIdentityServer/connect/token`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body = new HttpParams()
      .set('scope', 'ins ogc')
      .set('grant_type', 'client_credentials')
      .set('client_id', 'inserviceportal')
      .set('client_secret', 'secret');
    return this.http.post(tokenUrl, body.toString(), { headers }).pipe(
      map((response: any) => response.access_token)
    );
  }

  getData(): Observable<any> {
    debugger
    const url = `${this.baseUrl}/jobs`;
    return this.http.get(url, { headers: this.headerObj });
  }

  getActiveAssessmentJobs(): Observable<any> {
    const url = `${this.baseUrl}/INS/odata/AgencyEvents?$filter=IsOpen and AgencyId eq 'DMG'&$select=agencyEventID,LhJobStatus,AgencyId&$orderby=agencyEventID desc`;
    return this.http.get(url, { headers: this.headerObj });
  }

  getActiveTroubleCalls(): Observable<any> {
    return this.refreshToken().pipe(
      switchMap((newToken: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${newToken}`);
        // const url = `${this.baseUrl}/INS/odata/AgencyEvents?$expand=CommonEvent(%24select%3Dlocation)&$filter=IsOpen%20and%20AgencyId%20eq%20'DMG'&$select=agencyEventID%2CLhJobStatus%2CAgencyId%2CAgencyEventSubtypeCode%2CAgencyEventTypeCode%2CCreatedTime&$orderby=agencyEventID%20desc&$top=100&$count=true`;
        const url = `${this.baseUrl}/INS/odata/AgencyEvents?$expand=CommonEvent(%24select%3Dlocation,latitude,longitude)&$filter=IsOpen and AgencyEventTypeCode in ('LINES_CABLES','EQUIP_ISSUE','POWER_QUALITY','INTERFERENCE','EMERG_CALL')&$select=agencyEventID%2CLhJobStatus%2CAgencyId%2CAgencyEventSubtypeCode%2CAgencyEventTypeCode%2CCreatedTime%2CCustUnit&$orderby=agencyEventID%20desc&$top=100&$count=true`;
        return this.http.get(url, { headers });            
      }),
    );
  }

  getActiveDamageAssessors(): Observable<any> {
    const url = `${this.baseUrl}/INS/odata/ActiveUnits?$filter=AgencyId eq 'DMG'&$select=ShiftStart,UnitId,AgencyId`;
    return this.http.get(url, { headers: this.headerObj });
  }

  postTroubleCall(body: any): Observable<any> {
    debugger;
    const url = `${this.baseUrl}/INS/jobs`;
    return this.http.post(url, body, { headers: this.headerObj });
  }

  updateTroubleCall(callId: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/INS/jobs/${callId}`;
    return this.http.put(url, body, { headers: this.headerObj });
  }

  uploadImage(jobId, imgName, image: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', image, image.name);

    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    const url = `${this.baseUrl}/INS/jobs/${jobId}/attachments/${imgName}/false`;
    return this.http.post(url, formData, { headers: this.headerObj });
  }

  // fetchDataWithToken() {
  //   this.getToken().subscribe(
  //     (tokenResponse: any) => {
  //       this.token = tokenResponse.access_token;
  //     }, (error) => {
  //       console.log(error);
  //     });
  // }
  
  async pushUDData(udData) {
    await this.userDetailsData.next(udData);
  }

  getTroubleCallDataById(jobId){
    const url = `${this.baseUrl}/INS/jobs/${jobId}/remarks%2Cevnttype%2C%20evsubtyp%2CNAME%2C%20NON_OUTAGE_CAUSE%2C%20LH_JOB_STATUS`;
    return this.http.get(url, { headers: this.headerObj });
  }

  getAddress(latitude: number, longitude: number): Observable<any> {
    const url = `${this.geoCodeURl}?latlng=${latitude},${longitude}&key=${this.key}`;
    return this.http.get(url, {headers: this.headerObj});
  }

  getAttachments(jobId: number): Observable<any> {
    const url = `${this.baseUrl}/INS/jobs/${jobId}/attachments`;
    return this.http.get(url, {headers: this.headerObj});
  }

  getAttachedImage(imgId: number): Observable<Blob> {
    const url = `${this.baseUrl}/INS/odata/Attachments(${imgId})`;
    return this.http.get(url, { headers: this.headerObj, responseType: 'blob'});
  }

  getHazardsData(collectionName: string): Observable<any[]> {
    return this.angularFirestore.collection(collectionName).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }
}
