import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTransTitleCase',
})
export class TextTransTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.toLowerCase().split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
}

@Pipe({
  name: 'keyTransform'
})
export class KeyTransformPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, char => char.toUpperCase());
  }
}

@Pipe({
  name: 'capTitleCase',
})
export class CapTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }
}
