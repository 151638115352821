import { NgModule } from '@angular/core';
import { ToDatePipe } from './pipes/to-date';
import { PhonePipe } from './pipes/phone.pipe';
import { OrderByPipe } from './pipes/order-by';
import { KeyTransformPipe, TextTransTitleCasePipe, CapTitleCasePipe } from './pipes/text-trans-title-case.pipe';

@NgModule({
  declarations: [ToDatePipe, PhonePipe, OrderByPipe, CapTitleCasePipe, TextTransTitleCasePipe, KeyTransformPipe],
  exports: [ToDatePipe, PhonePipe, OrderByPipe, CapTitleCasePipe, TextTransTitleCasePipe, KeyTransformPipe],
  providers: [ToDatePipe, PhonePipe, OrderByPipe]
})
export class SharedPipeModule {}
