import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addmenu',
  templateUrl: './addmenu.component.html',
})
export class AddmenuComponent implements OnInit {
  constructor(private route: Router) {}

  @Input()
  public onClick = () => {};

  ngOnInit() {}

  navigateToEASY() {
    this.route.navigate(['/tabs/more/easy']);
    this.onClick();
  }
  navigateToHours() {
    this.route.navigate(['/tabs/more/hours']);
    this.onClick();
  }
  navigateToDepartments() {
    this.route.navigate(['/tabs/more/departments']);
    this.onClick();
  }
  navigateToTrucks() {
    this.route.navigate(['/tabs/more/trucks-map']);
    this.onClick();
  }
  navigateToAdministation() {
    this.route.navigate(['/tabs/more/administation']);
    this.onClick();
  }
}
