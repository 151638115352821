/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DamageAssessmentService } from './damage-assessment.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private damageAssessmentService: DamageAssessmentService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the Authorization header
    const token = this.damageAssessmentService.getToken();
    const clonedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 error by refreshing the token
          return this.damageAssessmentService.refreshToken().pipe(
            switchMap((newToken: string) => {
              // Update the token in the damageAssessmentService
              this.damageAssessmentService.setToken(newToken);

              // Clone the original request with the new token
              const newClonedRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`
                }
              });

              // Retry the original request with the new token
              return next.handle(newClonedRequest);
            })
          );
        }
        return throwError(error);
      })
    );
  }
}
