import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneMask',
})
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    return `(${phoneNumber.slice(0, 3)})
             ${phoneNumber.slice(3, 6)}
            - ${phoneNumber.slice(6, 10)}`;
  }
}
