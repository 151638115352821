import { NgModule } from '@angular/core';
// import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import more from 'highcharts/highcharts-more.src';
import solidGauge from 'highcharts/modules/solid-gauge.src';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

// GeoLocation
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

import { AppVersion } from '@ionic-native/app-version/ngx';

// Environment
import { environment } from '../environments/environment';

// Local Notifications
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { AuthInterceptor } from './shared/services/auth';
import { TextTransTitleCasePipe, CapTitleCasePipe } from './shared/pipes/text-trans-title-case.pipe';
import { SharedPipeModule } from './shared/sharedPipeModule';
import { DatePipe } from '@angular/common';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
export const highchartsModules = () =>
  // apply Highcharts Modules to this array
   [more, solidGauge]
;
const routes: Routes = [
  // Define your routes here
  ];
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        // AgmCoreModule.forRoot({
        //   apiKey: 'AIzaSyDTiUBRzd7BOrM1ElDFaC7mRGrKZS4AANs'
        // }),
        GoogleMapsModule,
        IonicModule.forRoot({
            mode: 'md'
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        HttpClientModule,
        ChartModule,
        SharedPipeModule,
        
        // RouterModule.forRoot(routes, { enableTracing: true })
    ],
    providers: [
        Geolocation,
        NativeGeocoder,
        AppVersion,
        LocalNotifications,
        DatePipe,
        TextTransTitleCasePipe,
        CapTitleCasePipe,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
        Base64ToGallery
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
